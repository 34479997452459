


















































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import DataRouteGuards from 'qs_vuetify/src/mixins/DataRouteGuards';
import ListMixin from 'qs_vuetify/src/mixins/ListMixin';

import { InstanceEvent } from 'qs_vuetify/src/types/models';
import { FiltersDefinition, RestParams } from 'qs_vuetify/src/types/states';

import QsSearch from '@/components/QsSearch/QsSearch.vue';

const instanceEvents: any = namespace('instance_events');
const view: any = namespace('instancesView');

@Component({
  components: {
    QsSearch,
  },
  filters: {
    icon(name: string): string {
      switch (name) {
        case 'Assemblée générale de fondation':
        case 'Création':
          return 'mdi-plus-circle';
        case 'Dissolution':
        case 'Fermeture':
          return 'mdi-close-circle';
        case 'Reconnaissance d\'Élections Québec':
        case 'Reconnaissance du parti':
          return 'mdi-stamper';
        case 'Suivi':
          return 'mdi-comment';
        default:
          return 'mdi-calendar';
      }
    },
  },
})
export default class InstanceEventsListDetail extends mixins(
  DataRouteGuards,
  ListMixin,
) {
  @instanceEvents.Getter('data') instanceEvents!: Array<InstanceEvent>;
  @instanceEvents.Getter('filtersDefinition') instanceEventsFiltersDefinition!: FiltersDefinition;
  @instanceEvents.Getter('loading') instanceEventsLoading!: boolean;
  @instanceEvents.Getter('total') instanceEventsTotal!: number;

  @view.Getter instanceEventsParams!: RestParams;
  @view.Mutation('instanceEventsParams') setInstanceEventsParams!: any;

  setInstanceEventsParam = this.buildSetParam('instance_roles', this.setInstanceEventsParams);
}
