import ListMixin from 'qs_vuetify/src/mixins/ListMixin';
import type { Route, RouteConfig } from 'vue-router';

import { Model, PersistedInstanceEvent, PersistedRightGroup } from 'qs_vuetify/src/types/models';

const InstanceEventsDetail = () => import('@/views/InstanceEvents/InstanceEventsDetail.vue');
const InstanceEventsDialog = () => import('@/views/InstanceEvents/InstanceEventsDialog.vue');
const InstanceGrantsDialog = () => import('@/views/InstanceGrants/InstanceGrantsDialog.vue');
const InstanceRolesDialog = () => import('@/views/InstanceRoles/InstanceRolesDialog.vue');
const InstanceUsersForm = () => import('@/views/InstanceUsers/InstanceUsersForm.vue');
const InstancesForm = () => import('@/views/Instances/InstancesForm.vue');
const Instances = () => import('@/views/Instances/Instances.vue');

const instanceFields = [
  'active_coordination.contact.email',
  'active_coordination.contact.full_name',
  'active_coordination.contact.home_phone',
  'active_representant_officiel.contact.email',
  'active_representant_officiel.contact.full_name',
  'active_representant_officiel.contact.home_phone',
  'can_adopt',
  'district_id',
  'district.name',
  'email',
  'filter',
  'id',
  'instance_type.name',
  'instance_type_id',
  'is_active',
  'name',
  'region_id',
  'region.id',
  'region.name',
].join(',');

const indexInstanceEventTypesIfNotLoaded = {
  index: {
    conditional(vm: Vue) {
      return !vm.$store.state.instance_event_types.lastLoadedAt;
    },
    params: {
      order: 'name',
      per_page: '*',
    },
  },
};

const indexInstanceEventsIfNotLoaded = {
  index: {
    conditional(vm: Vue) {
      return !vm.$store.state.instance_events.lastLoadedAt
        || vm.$store.state.instance_events.data
          ?.filter((e: PersistedInstanceEvent) => String(e.instance_id) !== String(vm.$route.params.id)).length > 0;
    },
    contextual(vm: Vue) {
      const { params } = vm.$store.state.instancesView.instanceEvents;
      return {
        ...ListMixin.buildListState(params),
      };
    },
    params: {
      order: '-date',
      fields: 'comment,date,created_by_user.contact_name,instance_event_type.name,instance.name',
    },
    prefix(vm: Vue) {
      return `/instances/${vm.$route.params.id}`;
    },
  },
};

const retrieveCurrentInstanceIfNotLoaded = {
  retrieve: {
    contextual(vm: Vue) {
      return {
        id: vm.$route.params.id,
      };
    },
    conditional(vm: Vue) {
      return vm.$store.state.instances?.item?.id
        !== parseInt(vm.$route.params.id, 10);
    },
    params: {
      fields: instanceFields,
    },
  },
};

const instances: RouteConfig[] = [
  {
    path: '/instances',
    name: 'Instances',
    component: Instances,
    meta: {
      data: {
        instances: {
          index: {
            params: {
              fields: instanceFields,
              per_page: 20,
              order: 'name',
            },
            conditional(vm: Vue) {
              return !vm.$store.state.instances.lastLoadedAt;
            },
          },
        },
        instance_types: {
          index: {
            params: {
              per_page: '*',
              order: 'id',
              fields: 'id,slug,name',
            },
            conditional(vm: Vue) {
              return !vm.$store.state.instance_types.loaded;
            },
          },
        },
        regions: {
          index: {
            params: {
              per_page: '*',
              order: 'name',
              fields: 'id,name',
            },
            conditional(vm: Vue) {
              return !vm.$store.state.regions.loaded;
            },
          },
        },
      },
      title: 'Instances',
      menus: ['primary'],
      requires: ['INSTANCES_RETRIEVE'],
    },
  },
  {
    path: '/instances/:id',
    redirect: (to: Route) => ({
      name: 'InstancesForm',
      params: {
        id: to.params.id,
        section: 'summary',
      },
    }),
  },
  {
    path: '/instances/:id/:section',
    name: 'InstancesForm',
    component: InstancesForm,
    props: (route) => {
      const parts = route.path.split('/');
      return {
        id: route.params.id,
        section: route.params.section || parts[3],
      };
    },
    meta: {
      data: {
        contacts: {
          index: {
            conditional(vm: Vue) {
              return vm.$route.params.section === 'contacts';
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$route.params.id}`;
            },
          },
        },
        districts: {
          index: {
            params: {
              fields: 'id,name',
              order: 'name',
              per_page: '*',
            },
            conditional(vm: Vue) {
              return vm.$route.params.section === 'contacts'
                || vm.$route.params.section === 'settings';
            },
          },
        },
        instances: {
          retrieve: {
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
            params: {
              fields: instanceFields,
            },
          },
          stats: {
            conditional(vm: Vue) {
              return vm.$route.params.section === 'summary'
                && (
                  !vm.$store.state.instances.statsLastLoadedAt
                  || vm.$store.state.instances.stats.id !== parseInt(vm.$route.params.id, 10)
                );
            },
            contextual(vm: Vue) {
              return {
                id: vm.$route.params.id,
              };
            },
          },
        },
        instance_grants: {
          index: {
            conditional(vm: Vue) {
              return vm.$route.params.section === 'grants';
            },
            params: {
              fields: '*,contact.full_name,contact.email,instance.name,user.email',
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$route.params.id}`;
            },
          },
        },
        instance_events: {
          index: {
            conditional(vm: Vue) {
              return vm.$route.params.section === 'events';
            },
            params: {
              order: '-date',
              per_page: '*',
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$route.params.id}`;
            },
          },
        },
        instance_event_types: { ...indexInstanceEventTypesIfNotLoaded },
        instance_roles: {
          index: {
            conditional(vm: Vue) {
              return vm.$route.params.section === 'roles';
            },
            params: {
              fields: [
                'id',
                'contact_name',
                'end_at',
                'is_active',
                'responsibilities.name',
                'start_at',
                'type_name',
              ].join(','),
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$route.params.id}`;
            },
          },
        },
        instance_role_responsibilities: {
          index: {
            conditional(vm: Vue) {
              return ['contacts', 'extra'].includes(vm.$route.params.section)
                && (!vm.$store.state.instance_role_responsibilities.lastLoadedAt
                || vm.$store.state.instance_role_responsibilities.data.length
                  !== vm.$store.state.instance_role_responsibilities.total);
            },
            params: {
              fields: 'id,name',
              order: 'name',
              per_page: '*',
            },
          },
        },
        instance_role_types: {
          index: {
            params: {
              order: 'name',
              per_page: '*',
            },
            conditional(vm: Vue) {
              return vm.$route.params.section === 'roles'
                && (!vm.$store.state.instance_role_types.lastLoadedAt
                || vm.$store.state.instance_role_types.data.length
                  !== vm.$store.state.instance_role_types.total);
            },
          },
        },
        instance_types: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.instance_types.lastLoadedAt;
            },
            params: {
              per_page: '*',
              order: 'name',
              fields: 'id,slug,name',
            },
          },
        },
        instance_users: {
          index: {
            conditional(vm: Vue) {
              return vm.$route.params.section === 'users';
            },
            params: {
              fields: [
                'id',
                'contact_name',
                'start_at',
                'end_at',
                'is_active',
                'instance_id',
              ].join(','),
            },
            prefix(vm: Vue) {
              return `/instances/${vm.$route.params.id}`;
            },
          },
        },
        regions: {
          index: {
            params: {
              per_page: '*',
              order: 'name',
              fields: 'id,name',
            },
            conditional(vm: Vue) {
              return vm.$route.params.section === 'settings';
            },
          },
        },
        rights: {
          index: {
            params: {
              fields: 'id,slug,description',
              per_page: '*',
              order: 'slug',
            },
            conditional(vm: Vue) {
              return vm.$route.params.section === 'users';
            },
          },
        },
        right_groups: {
          index: {
            conditional(vm: Vue) {
              return !vm.$store.state.right_groups.lastLoadedAt
                && (vm.$route.params.section === 'users'
                  || vm.$route.params.section === 'grants');
            },
            params: {
              fields: 'id,name,description,rights.id',
              per_page: '*',
              order: 'name',
            },
          },
        },
      },
      title: 'Instance',
      menus: [],
      requires: ['INSTANCES_UPDATE'],
    },
    children: [
      {
        path: '/instances/:id/events/new',
        name: 'InstanceEventsDialog',
        component: InstanceEventsDialog,
        props: (route) => ({
          id: route.params.id,
          eventId: route.params.eventId,
          section: 'events',
        }),
        meta: {
          data: {
            instances: { ...retrieveCurrentInstanceIfNotLoaded },
            instance_events: {
              ...indexInstanceEventsIfNotLoaded,
              loadEmpty: {},
            },
            instance_event_types: { ...indexInstanceEventTypesIfNotLoaded },
          },
          title: "Suivi d'instance",
          menus: [],
          requires: ['INSTANCE_EVENT_TYPES_CREATE'],
        },
      },
      {
        path: '/instances/:id/events/:eventId',
        name: 'InstanceEventsDetail',
        component: InstanceEventsDetail,
        props: (route) => ({
          id: route.params.id,
          eventId: route.params.eventId,
          section: 'events',
        }),
        meta: {
          data: {
            instances: { ...retrieveCurrentInstanceIfNotLoaded },
            instance_events: {
              ...indexInstanceEventsIfNotLoaded,
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.eventId,
                  };
                },
                params: {
                  fields: [
                    '*',
                    'created_by_user.contact_name',
                    'instance_event_type.name',
                  ].join(','),
                },
              },
            },
            instance_event_types: { ...indexInstanceEventTypesIfNotLoaded },
          },
          title: "Suivi d'instance",
          menus: [],
          requires: ['INSTANCE_EVENT_TYPES_RETRIEVE'],
        },
      },
      {
        path: '/instances/:id/grants/:contactId/:userId?',
        name: 'InstancesInstanceGrantsDialog',
        component: InstanceGrantsDialog,
        props: (route) => ({
          id: route.params.id,
          contactId: route.params.contactId,
          section: 'grants',
        }),
        meta: {
          data: {
            instances: { ...retrieveCurrentInstanceIfNotLoaded },
            instance_grants: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_grants.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.instancesView.instanceGrants;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
                params: {
                  fields: '*,contact.full_name,instance_roles.*,instance_users.*,user.email',
                },
                prefix(vm: Vue) {
                  return `/instances/${vm.$route.params.id}`;
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.contactId === 'new';
                },
                transform(vm: Vue, item: Model) {
                  if (!item.instance_id) {
                    return {
                      ...item,
                      instance_id: vm.$route.params.id,
                    };
                  }

                  return item;
                },
              },
              retrieveByContactId: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.contactId,
                    user_id: vm.$route.params.userId,
                  };
                },
                conditional(vm: Vue) {
                  return vm.$route.params.contactId !== 'new';
                },
                params: {
                  fields: [
                    '*',
                    [
                      'age',
                      'birthdate',
                      'district.name',
                      'email',
                      'full_name',
                      'postal_code',
                      'searchable_text',
                      'status',
                      'user',
                      'v1_contact_id',
                    ].map((f: string) => `contact.${f}`),
                    [
                      'end_at',
                      'instance_id',
                      'is_active',
                      'type_name',
                      'responsibilities.name',
                      'start_at',
                    ].map((f: string) => `instance_roles.${f}`),
                    [
                      'end_at',
                      'instance_id',
                      'is_active',
                      'rights.slug',
                      'start_at',
                    ].map((f: string) => `instance_users.${f}`),
                    // 'instance_users.*',
                  ].flat().join(','),
                },
                prefix(vm: Vue) {
                  return `/instances/${vm.$route.params.id}`;
                },
              },
            },
            instance_roles: {
              loadEmpty: {
                transform(vm: Vue, item: Model) {
                  const newItem = { ...item };

                  if (vm.$store.state.instancesView.defaultEndAt) {
                    newItem.end_at = vm.$store.state.instancesView.defaultEndAt;
                  }

                  if (!item.instance_id) {
                    newItem.instance_id = vm.$route.params.id;
                  }

                  return newItem;
                },
              },
            },
            instance_role_types: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_role_types.lastLoadedAt
                    || vm.$store.state.instance_role_types.data.length
                      !== vm.$store.state.instance_role_types.total;
                },
                params: {
                  order: 'name',
                  per_page: '*',
                },
              },
            },
            instance_role_responsibilities: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_role_responsibilities.lastLoadedAt
                    || vm.$store.state.instance_role_responsibilities.data.length
                      !== vm.$store.state.instance_role_responsibilities.total;
                },
                params: {
                  fields: 'id,name',
                  order: 'name',
                  per_page: '*',
                },
              },
            },
            instance_users: {
              loadEmpty: {
                transform(vm: Vue, item: Model) {
                  const newItem = { ...item };

                  if (vm.$store.state.instancesView.defaultEndAt) {
                    newItem.end_at = vm.$store.state.instancesView.defaultEndAt;
                  }

                  if (!item.instance_id) {
                    newItem.instance_id = vm.$route.params.id;
                  }

                  return item;
                },
              },
            },
            right_groups: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.right_groups.lastLoadedAt;
                },
                params: {
                  fields: 'id,name,description,rights.slug',
                  per_page: '*',
                  order: 'name',
                },
              },
            },
          },
          menus: [],
          requires: ['INSTANCE_ROLE_TYPES_RETRIEVE'],
        },
      },
      {
        path: '/instances/:id/roles/:roleId',
        name: 'InstancesInstanceRolesDialog',
        component: InstanceRolesDialog,
        props: (route) => ({
          id: route.params.id,
          roleId: route.params.roleId,
          section: 'roles',
        }),
        meta: {
          data: {
            instances: { ...retrieveCurrentInstanceIfNotLoaded },
            instance_roles: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_roles.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.instancesView.instanceRoles;
                  return {
                    ...ListMixin.buildListState(options, params),
                  };
                },
                params: {
                  fields: [
                    '*',
                    'contact.id',
                    'contact.district.name',
                    'contact.full_name',
                    'contact.status',
                    'contact.v1_contact_id',
                    'instance_role_type.name',
                    'responsibilities.name',
                  ].join(','),
                },
                prefix(vm: Vue) {
                  return `/instances/${vm.$route.params.id}`;
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.roleId === 'new'
                    && !vm.$store.state.instance_roles?.item?.instance_grant_id;
                },
                transform(vm: Vue, item: Model) {
                  const newItem = { ...item };

                  if (vm.$store.state.instancesView.defaultEndAt) {
                    newItem.end_at = vm.$store.state.instancesView.defaultEndAt;
                  }

                  if (!item.instance_id) {
                    newItem.instance_id = vm.$route.params.id;
                  }

                  return newItem;
                },
              },
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.roleId,
                  };
                },
                conditional(vm: Vue) {
                  return vm.$route.params.roleId !== 'new';
                },
                params: {
                  fields: [
                    '*',
                    'contact.id',
                    'contact.full_name',
                    'contact.v1_contact_id',
                    'instance_role_type.name',
                    'responsibilities.name',
                  ].join(','),
                },
              },
            },
            instance_role_types: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_role_types.lastLoadedAt
                    && vm.$store.state.instance_role_types.data.length
                      !== vm.$store.state.instance_role_types.total;
                },
                params: {
                  order: 'name',
                  per_page: '*',
                },
              },
            },
            instance_role_responsibilities: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_role_responsibilities.lastLoadedAt
                    || vm.$store.state.instance_role_responsibilities.data.length
                      !== vm.$store.state.instance_role_responsibilities.total;
                },
                params: {
                  fields: 'id,name',
                  order: 'name',
                  per_page: '*',
                },
              },
            },
          },
          menus: [],
          requires: ['INSTANCE_ROLES_RETRIEVE'],
        },
      },
      {
        path: '/instances/:id/users/:instanceUserId',
        name: 'InstanceUsersForm',
        component: InstanceUsersForm,
        props: (route) => ({
          id: route.params.id,
          instanceUserId: route.params.instanceUserId,
          section: 'users',
        }),
        meta: {
          data: {
            instances: { ...retrieveCurrentInstanceIfNotLoaded },
            instance_users: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.instance_users.lastLoadedAt;
                },
                contextual(vm: Vue) {
                  const { options, params } = vm.$store.state.instancesView.instanceRoles;
                  return {
                    ...ListMixin.buildListState(options, params),
                    'instance.id': vm.$route.params.id,
                  };
                },
                params: {
                  fields: 'id,contact_name,start_at,end_at,is_active,instance_id',
                },
              },
              loadEmpty: {
                conditional(vm: Vue) {
                  return vm.$route.params.instanceUserId === 'new'
                    && !vm.$store.state.instance_users.item?.instance_grant_id;
                },
                transform(vm: Vue, item: Model) {
                  const newItem = { ...item };

                  if (vm.$store.state.instancesView.defaultEndAt) {
                    newItem.end_at = vm.$store.state.instancesView.defaultEndAt;
                    newItem.rights = [
                      ...vm.$store.state.instancesView.defaultRightGroups
                        .map((r: PersistedRightGroup) => r.rights).flat(),
                    ];
                  }

                  if (!item.instance_id) {
                    newItem.instance_id = vm.$route.params.id;
                  }

                  return newItem;
                },
              },
              retrieve: {
                contextual(vm: Vue) {
                  return {
                    id: vm.$route.params.instanceUserId,
                  };
                },
                conditional(vm: Vue) {
                  return vm.$route.params.instanceUserId !== 'new';
                },
                params: {
                  fields: [
                    '*',
                    'rights.*',
                    'user.contact.district.name',
                    'user.contact.email',
                    'user.contact.full_name',
                    'user.contact.searchable_text',
                    'user.contact.status',
                    'user.contact.v1_contact_id'].join(','),
                },
              },
            },
            rights: {
              index: {
                params: {
                  fields: 'id,slug,description',
                  per_page: '*',
                  order: 'slug',
                },
              },
            },
            right_groups: {
              index: {
                conditional(vm: Vue) {
                  return !vm.$store.state.right_groups.lastLoadedAt;
                },
                params: {
                  fields: 'id,name,description,rights.slug',
                  per_page: '*',
                  order: 'name',
                },
              },
            },
          },
          title: "Utilisateur d'instance",
          menus: [],
          requires: ['INSTANCE_USERS_RETRIEVE'],
        },
      },
    ],
  },
];

export default instances;
