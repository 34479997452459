var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "instance-role-types-list", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "12", md: "3" } },
            [
              _c("qs-filters", {
                attrs: {
                  "active-filters": _vm.params,
                  "filters-definition": _vm.filtersDefinition,
                  "model-name": _vm.slug,
                  theme: "labase"
                },
                on: { input: _vm.setInstanceRoleResponsibilitiesParam }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "12", md: "9" } },
            [
              _c("qs-data-table", {
                attrs: {
                  actions: _vm.tableActions,
                  headers: _vm.headers,
                  items: _vm.data,
                  loading: !_vm.routeDataLoaded || _vm.loading,
                  options: _vm.options,
                  "set-options": _vm.setOptions,
                  theme: "labase",
                  total: _vm.total || 0,
                  tile: ""
                },
                on: {
                  delete: function($event) {
                    return _vm.confirmAndDeleteInstanceRoleResponsibility(
                      $event
                    )
                  },
                  edit: function($event) {
                    return _vm.goto("instance_role_responsibilities", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.$route.params.id ? _c("router-view") : _vm._e(),
      _c(
        "qs-confirmation-modal",
        _vm._b(
          {
            on: {
              "click:cancel": function($event) {
                _vm.listDialog.value = false
              },
              "click:confirm": _vm.listDialog.callback
            }
          },
          "qs-confirmation-modal",
          _vm.listDialog,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }